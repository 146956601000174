export const environment = {
  production: false,
  url: 'https://api.dev.turnea.com.ar/api',
  urlWS: 'https://api.dev.turnea.com.ar',
  urlApp: 'https://api.dev.turnea.com.ar/api',
  urlNuvalid: 'https://nuvalid-dev.puntosalud.ar/#/inicio',
  APP_LANG: 'es',
  APP_CURR: 'ars',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCiMMYQFBB8n4kMmRqG5KVegf0WEGYrxNw',
  RECAPTCHA_KEY: '6LcMWNMjAAAAADC82sC5_xl2ZBiQwv93x9L1aVl2',
  ga: 'G-97TV13SECQ',
  //mercadopago
  //testing
  PUBLIC_KEY_MP:'TEST-261a2a55-4ea8-4e25-aad3-e951e7ecaea8'
  //production
  // PUBLIC_KEY_MP:'APP_USR-646dcb52-8bf6-4dc2-a98f-29d5460d0832'
};
